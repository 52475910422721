/* eslint-disable global-require */
import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Promo } from '@latitude/promo';
import { Link } from '@latitude/link';
import { ImportantInformation } from '@latitude/important-information';
import Section from '@latitude/section';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { BREAKPOINT } from '@latitude/core/utils/constants';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import FlexiRoam from './_flexi_roam';
import flightDelayPromoImage from './images/28d_card_holding_image.webp';
import QuickStartFlexiGuide from './_quick_start_guide-flexi';
import HaveAQuestionFlexiSection from './_have-a-question-flexi';
import DegreesCardMicrositeHeader from './_28d-microsite-header';
import heroImage from './images/flexiroam_image_hero.webp';
import footerData from './data/footer.json';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { useSetState } from '@/utils/hooks';

const flexiroamItems = [
  {
    icon: 'icon-global-wifi',
    title: '3GB free global data*',
    text: 'Per calendar year. Valid for 15 days from your chosen start date.'
  },
  {
    icon: 'icon-credit-card',
    title: '15% discount on data*',
    text:
      'On Flexiroam data plans purchased with your Latitude 28° Mastercard credit card.'
  },
  {
    icon: 'icon-app-latitude-branded',
    title: 'Three SIM options',
    text:
      'New users can choose between a Flexiroam SIM card, Microchip or eSIM.'
  }
];

const quickstartguideItems = [
  {
    icon: 'iphone',
    title: 'Download Flexiroam X App',
    text: 'You’ll need your Latitude 28° Mastercard credit card  to redeem.'
  },
  {
    icon: 'list',
    title: 'Select your SIM option',
    text:
      'New users can choose a Flexiroam SIM card, Microchip or eSIM. For help setting up your SIM, check out the <a target="_blank" href="https://mastercard.flexiroam.com/mastercard/tutorials">Flexiroam tutorial</a>.'
  },
  {
    icon: 'tick',
    title: 'Activate your data plan',
    text:
      'Your free 3GB of data is valid for 15 days so choose the correct start date*.'
  },
  {
    icon: 'snapfingers',
    title: 'Top up your data',
    text:
      'Purchase additional data with your Latitude 28° Mastercard credit card and enjoy 15% off*.'
  }
];

function FlexiroamPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading:
        'Exclusive Flexiroam features for </br>Latitude 28° Mastercard travellers.',
      featureCards: flexiroamItems
    },
    {
      heading: 'The quick start guide for using </br> Flexiroam.',
      featureCards: quickstartguideItems
    }
  ];
  /** - END - */

  return (
    <Layout
      customFooter={footerData}
      location={props.location}
      stickyCtaTrigger="#rates"
      hasSecNav
    >
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/global-data-roaming"
          />
          <meta
            name="description"
            content="Shop with less obstacles. The Latitude 28&deg; Global Platinum Mastercard is perfect for online shopping and is a great travel companion. Read the full credit card details."
          />
          <meta name="keywords" content="credit card details, 28 degrees" />
        </Helmet>
        <DegreesCardMicrositeHeader />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
        <PageHeaderSection
          title="Enjoy internet access the second you land with Flexiroam."
          subTitle={
            <>
              Stay connected anywhere in the world with free global data roaming
              in 150+ countries with your Latitude 28&deg; Global Platinum
              Mastercard credit card and Flexiroam.
            </>
          }
          pageImage={heroImage}
          pageImageAlt="flexiroam icon"
        />)}

        <span data-header-trigger />

        <FlexiRoam
          anchor="why-us"
          featureData={state?.featureSliderData?.[0] || featureSliderData[0]}
        >
          <Link
            button="secondary"
            href="https://mastercard.flexiroam.com/"
            trackId="28degrees_intro__-button"
            css={{ width: 'fit-content', margin: '0 auto' }}
          >
            Learn more
          </Link>
        </FlexiRoam>

        <QuickStartFlexiGuide
          anchor="why-us"
          featureData={state?.featureSliderData?.[1] || featureSliderData[1]}
          css={`background-color: #fff; !important`}
        />

        <HorizontalRule
          css={`
            background-color: #fff;
          `}
        />
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <Section
            id="flight-delay-pass"
            css={`
              && {
                @media (max-width: ${BREAKPOINT.LG}) {
                  padding: 40px 0 calc(40px - 24px);
                }
                background-color: #fff;
                padding: 72px 0;
              }
            `}
          >
            <div css="max-width: 920px; margin: 0 auto;">
              <AnalyticsLocationProvider location="Turn a delay into a delight">
                <Promo
                  title={"Don't have a card yet?"}
                  description={
                    <p>
                      With no international transaction or currency conversion
                      fees on purchases, your Latitude 28° Global Platinum
                      Mastercard credit card helps you pay like a local, online
                      or overseas.
                    </p>
                  }
                  verticalAlignContent
                  frameImage={
                    <img src={flightDelayPromoImage} alt="flight delay pass" />
                  }
                  frameAlignment="right"
                  frameBackgroundColor="#ED8EA7"
                  frameBorderColor="#FF94CA"
                  ctaButtons={
                    <div>
                      <Link
                        button="primary"
                        href="https://cards.latitudefinancial.com/28degrees"
                        trackId="28degrees_intro__-button"
                        css={`
                          && {
                            margin-left: auto;
                          }
                        `}
                      >
                        Apply now
                      </Link>
                    </div>
                  }
                />
              </AnalyticsLocationProvider>
            </div>
          </Section>
        )}

        <Section
          css={`
            && {
              padding: 48px 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 0;
              }
            }
          `}
          id="calculate"
        />

        <HaveAQuestionFlexiSection
          anchor="question"
          heading="Have a question?"
        />

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                "<sup id='note-star'>*</sup>&nbsp;Terms and conditions apply. Visit <a href='https://mastercard.flexiroam.com/' target='_blank'>Flexiroam</a> or the <a href='/credit-cards/28-degrees/faqs/' target='_blank'>FAQs</a> for more information.",
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
        />
      </main>
    </Layout>
  );
}

export default FlexiroamPage;
